import { render, staticRenderFns } from "./IndividualUsersBindingCard.vue?vue&type=template&id=42b4f21d&scoped=true&"
import script from "./IndividualUsersBindingCard.vue?vue&type=script&lang=js&"
export * from "./IndividualUsersBindingCard.vue?vue&type=script&lang=js&"
import style0 from "./IndividualUsersBindingCard.vue?vue&type=style&index=0&id=42b4f21d&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b4f21d",
  null
  
)

export default component.exports