<template>
	<!-- 个人用户绑卡 -->
	<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
		<div class="form-Box">
			<div class="title flex-row-align-center text-center">
				<i class="iconfont icon-biaodan font-size30"></i>
				<div class="font-size16">{{ title }}</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
				<el-form-item label="持卡人姓名">
					<el-input v-model="userinfo.fCustomerName" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="身份证号">
					<el-input v-model="userinfo.fIdentityCode" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号" prop="fAccountNumber">
					<el-input v-model="ruleForm.fAccountNumber" onkeyup="this.value=this.value.replace(/[^\d]/g,'')" @change="getBankName">
					</el-input>
				</el-form-item>
				<el-form-item v-if="this.type == 1 && ruleForm.fBankTypeID" label="银行">
					<div style="position: relative; height: 40px;">
						<div class="bankbox" style="display: flex; height: 40px; box-sizing: border-box; padding: 0 10px; width: 380px;background: linear-gradient(to right, #ebf0f6, #fff);">
							<img v-if="this.bankImg" :src="this.bankImg" alt="" style="width: 30px; height: 30px; line-height: 30px; margin-top: 5px;">
							<el-input style="display: none;" v-model="ruleForm.fBankTypeID" autocomplete="off" disabled />
							<div style="height: 40px; line-height: 40px; font-size: 17px; margin-left: 5px;">{{bankName}} <span :class= "this.fCardMediaType === 1 ? 'debitcard' : 'creditcard'">{{fCardMediaTypeName}}</span></div>
						</div>
					</div>
				</el-form-item>
				<!-- <el-form-item v-if="this.type == 1" label="银行" ->
					<div style="height: 50px; line-height: 50px; padding-top: 5px; box-sizing: border-box;">
						<div class="bankbox" style="display: flex;border: 1px solid #ccc; border-radius: 4px; height: 40px; box-sizing: border-box; padding: 0 10px; width: 380px; background-color: #f5f7fa;">
							<img v-if="this.bankImg" :src="this.bankImg" alt="" style="width: 30px; height: 30px; line-height: 30px; margin-top: 5px;">
							<el-input style="display: none;" v-model="ruleForm.fBankTypeID" autocomplete="off" disabled />
							<div style="height: 40px; line-height: 40px; color: #c0c4cc; margin-left: 10px;">{{bankName}}</div>
						</div>
					</div>
				</el-form-item> -->
				<el-form-item v-if="this.type == 2" label="开户行" prop="fBankTypeID">
					<el-select v-model="ruleForm.fBankTypeID" placeholder="请选择开户行" @change="selectChange">
						<el-option v-for="(item, index) in bankList" :key="index" :label="item.fBankTypeName" :value="item.fBankTypeID"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="this.type == 2" label="支行信息" prop="fBranchName" style="line-height: none;position: relative;">
					<el-input placeholder="请选择支行信息" disabled :value="ruleForm.fBranchName">
					</el-input>
					<div class="button-Branch background-color-theme pointer" @click="ToBranchSelection">请选择...</div>
					<div style="color: #ff9900;font-size: 14px;line-height: 10px;">
						支行信息选择错误会影响提现，请仔细核对。
					</div>
				</el-form-item>
				<el-form-item label="银行预留号码" prop="fPhone">
					<el-input v-model="ruleForm.fPhone" maxlength="11" onkeyup="this.value=this.value.replace(/[^\d]/g,'')" />
					</el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="fCheckCode">
					<div v-show="innerVisibles" style="position: absolute;bottom: 0px;z-index: 999; padding: 3px; background-color: #fff; left: 0;">
						<i class="el-icon-circle-close" style="position: absolute;right: -20px;top: -20px;color: #0173FE;font-size:26px;"
						 @click="closeYanzheng"></i>
						<Graphic @messageData='messageData'></Graphic>
					</div>
					<div class="codeRlBox flex-row-space-between margin-t-5">
						<el-input v-model="ruleForm.fCheckCode" autocomplete="off" placeholder="请输入验证码" style="width: 70%">
							<div v-if="verificationCodeText=='获取验证码'" class="color-theme sendCode pointer" slot="suffix" @click="sendCode('ruleForm')">
								{{ verificationCodeText }}
							</div>
							<div v-else class="color-theme sendCode" slot="suffix" @click="sendCode('ruleForm')">
								{{ verificationCodeText }}秒重新获取
							</div>
						</el-input>
					</div>
				</el-form-item>
				<el-form-item v-if="title!='绑定银行卡'" label="">
					<div class="tip font-color-FF9900 margin-b-10">*绑定账服通支付卡时，系统将为您默认同时将该卡绑定为提现卡。可在银行卡管理处进行解绑操作。也可进行单独绑定提现卡。</div>
				</el-form-item>
				<el-form-item label="" id="setform" prop="radio" v-if="fAccountname=='开通账服通'">
					<div style="line-height: 20px !important;">
						<el-checkbox-group v-model="ruleForm.radio">
							<el-checkbox label="我已阅读并同意"></el-checkbox>
						</el-checkbox-group>
						<span v-for="(item,index) in zftAgreement" :key='index' style="display: inline-block;line-height: 20px;">
							<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
						</span>
					</div>
				</el-form-item>
				<el-form-item>
					<el-button class="background-color-theme" @click="save" :disabled="buttonloading" v-loading='buttonloading'>{{fAccountname=='开通账服通'?'申请开通':'确认绑定'}}</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
		<agreementDialog :iContents='iContent' ref="areDialog"></agreementDialog>
		<BranchSelection v-if="BranchSelection" :showModel='BranchSelection' :id="fBankCode" @change="branchChange"></BranchSelection>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import Graphic from '@/components/Graphic';
	import agreementDialog from '@/components/agreementDialog';
	import BranchSelection from './BranchSelection';
	export default {
		data() {
			// 银行卡号校验规则
			var bankAccountValid = (rule, value, callback) => {
				const strBin =
					"10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99";
				if (!value) {
					return callback(new Error("收款账户不能为空"));
				} else if (!Number.isInteger(+value)) {
					callback(new Error("银行卡号必须全为数字"));
				} else if (value.trim().length < 12 || value.trim().length > 19) {
					callback(new Error("银行卡号长度必须在12到19之间"));
				} else if (strBin.indexOf(value.substring(0, 2)) === -1) {
					callback(new Error("银行卡号开头6位不符合规范"));
				} else {
					callback();
				}
			};
			// 验证手机号的校验规则
			let checkMobile = (rule, value, callback) => {
				// 验证手机号的正则表达式
				const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
				if (regMobile.test(value)) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("请输入正确的手机号"));
			};
			return {
				bankImg: '',
				bankName: '',
				fCardMediaTypeName: '',
				fCardMediaType: 0,
				message: '',
				
				noticeShow: false,
				iContent: '',
				fAccountname: '',
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: "80px",
				loading: true,
				innerVisibles: false,
				title: "", //标题
				type: 1, //1 中金,2 账服通
				userinfo: {}, //用户信息
				bankList: [], //开户行
				fAccountID: "", //验证码获取成功后返回的id
				bankListUrl: '', //获取开户行的url
				sendCodeUrl: "", //获取验证码的url
				submitUrl: "", //提交的url
				msg: false,
				verificationCodeText: "获取验证码",
				BranchSelection: false,
				buttonloading: false,
				fBankCode: '',
				ss: "",
				ruleForm: {
					radio: false,
					fAccountNumber: "",
					fBankTypeID: "",
					fPhone: "",
					fCheckCode: "", //验证码
					fBranchName: '',
					fBranchID: '',
				},
				rules: {
					fAccountNumber: [{
							required: true,
							message: "请输入银行卡号",
							trigger: "blur"
						}, {
							min: 12,
							message: '银行账号不少于12位',
							trigger: 'blur'
						}
						// {
						//   validator: bankAccountValid,
						//   trigger: "blur",
						// },
					],
					fBankTypeID: [{
						required: true,
						message: "请选择开户行",
						trigger: "change",
					}],
					fBranchName: [{
						required: true,
						message: '请选择支行信息',
						trigger: 'change'
					}],
					fPhone: [{
							required: true,
							message: "请输入银行预留号码",
							trigger: "blur",
						},
						{
							validator: checkMobile,
							trigger: "blur",
						},
					],
					fCheckCode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur",
					}],
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意开通账服通协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				},
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(["getUserInfo"]),
		},
		components: {
			agreementDialog,
			Graphic,
			BranchSelection
		},
		created() {
			// this.ruleForm = {};
			this.title = this.$route.query.title;
			this.type = this.$route.query.type
			this.fAccountname = this.$route.query.fAccountname;
			if (this.fAccountname == '') {
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate(['radio'])
				})
			}
			console.log(this.title);
			//   个人用户的现金钱包和账服通公用一个页面,调的接口不同
			if (this.type == 1) {
				this.bankListUrl = "api/mall/ebbase/bank-type/get-cpcn-banktype";
				this.sendCodeUrl =
					"api/mall/ebcustomer/bank-account/create-cpcn-b2c-account-send-msg";
				this.submitUrl =
					"api/mall/ebcustomer/bank-account/create-cpcn-b2c-account";
			}
			if (this.type == 2) {
				this.bankListUrl = "/api/mall/ebbase/bank-type/get-acs-banktype";
				this.sendCodeUrl =
					"api/mall/ebcustomer/bank-account/create-acs-b2c-account-send-msg";
				this.submitUrl =
					"api/mall/ebcustomer/bank-account/create-acs-b2c-account";
			}
			console.log(this.title);
			this.getInfo();
			this.getBankList();
			this.loading = false
		},
		mounted() {
			this.getReadAgreement();
		},
		methods: {
			getBankName() {
				if (this.type == 1) {
					console.log(this.ruleForm.fAccountNumber)
					this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/get-account-info', {fAccountNumber: this.ruleForm.fAccountNumber.split(/[\t\r\f\n\s]*/g).join('')})
					.then(res => {
						console.log(res)
						this.ruleForm.fBankTypeID = res.obj.fBankTypeID
						this.bankImg = res.obj.fIconPath
						this.bankName = res.obj.fBankTypeName
						this.fCardMediaTypeName = res.obj.fCardMediaTypeName
						this.fCardMediaType = res.obj.fCardMediaType
						this.message = ''
					}, err => {
						console.error((err))
						this.ruleForm.fBankTypeID = ''
						this.bankImg = ''
						this.bankName = ''
						this.fCardMediaType = 0
						this.message = err.error.message
					})
				}
				
			},
			messageData(a) {
				if (a) {
					var _this = this;
					setTimeout(function() {
						_this.innerVisibles = false;
						_this.msg = a
						_this.sendCode();
						console.log('子传递值，已经变成true')
					}, 500);
				}
			},
			// 关闭验证码
			closeYanzheng() {
				this.innerVisibles = false
			},
			//获取账服通协议列表
			getReadAgreement() {
				this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-by-asc-list', {}).then(
					(result) => {
						if (result.obj) {
							this.zftAgreement = result.obj.items
						}
					},
					(rej) => {}
				);
			},
			closeNoticeShows() {
				this.noticeShow = false;
			},
			shows() {
				this.noticeShow = true;
			},
			showAgreeDia(a) {

				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//   获取用户信息
			getInfo() {
				this.ApiRequestPost("api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full", {}).then(
					(result) => {
						console.log(result, "00000000000");
						this.userinfo = result.obj;
					},
					(rej) => {}
				);
			},
			//   获取开户行下拉框数据
			getBankList() {
				this.ApiRequestPost(this.bankListUrl, {}).then(
					(result) => {
						this.bankList = result.obj.items;
						console.log(result.obj, "00000000000");
					},
					(rej) => {}
				);
			},
			selectChange(val) {
				console.log(val, 'asdasdasd');
				let fBankTypeCode = this.bankList.find(p => p.fBankTypeID == this.ruleForm.fBankTypeID);
				this.fBankCode = fBankTypeCode.fBankTypeCode;
			},
			//获取验证码
			sendCode(formName) {
				if (this.type == 1 && this.fCardMediaType == 2) {
					this.$message.warning('银行卡类型为贷记卡，不可绑定')
					return
				}
				if (this.ruleForm.fPhone == "") {
					return;
				}
				if (this.ruleForm.fPhone.length != 11) {
					return;
				}
				// 如果验证码没有输入就打开
				if (this.msg == false) {
					this.innerVisibles = true;
					return;
				}
				// 倒计时没有结束不能点击
				if (this.verificationCodeText != "获取验证码") {
					return;
				}
				this.ApiRequestPost(
					this.sendCodeUrl, {
						fAccountNumber: this.ruleForm.fAccountNumber,
						fBankTypeID: this.ruleForm.fBankTypeID,
						fPhone: this.ruleForm.fPhone,
						fBranchID: this.ruleForm.fBranchID,
					},
				).then(
					(result) => {

						if (result.code == "HengHeCode1000") {
							this.fAccountID = result.obj.fAccountID

							// 验证通过,倒计时开启
							this.$message({
								type: 'success',
								message: '发送成功'
							});
							this.verificationCodeText = 120;
							this.ss = "S";
							let time = setInterval(() => {
								this.verificationCodeText--;
								if (this.verificationCodeText == 0) {
									clearInterval(time);
									this.ss = "";
									this.verificationCodeText = "获取验证码";
									this.msg = false
								}
							}, 1000);

						} else {
							this.$message({
								type: 'error',
								message: result.message
							});
						}
					},
					(rej) => {}
				);
				// } else {

				// 	return false;
				// }
			},
			//选择支行弹窗
			ToBranchSelection() {
				if (this.ruleForm.fBankTypeID == '' || this.ruleForm.fBankTypeID == undefined || this.ruleForm.fBankTypeID == null) {
					this.$message.info('请先选择开户行!')
					return
				} else {
					this.BranchSelection = true;
				}
			},
			//选择支行回传数据
			branchChange(e, data) {
				this.BranchSelection = e;
				if (data.isChange) {
					this.ruleForm.fBranchName = data.data[0].fkhmC1
					this.ruleForm.fBranchID = data.data[0].fqhhO2
				}
			},
			save() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						console.log(this.message)
						if (this.message !== '') {
							this.$message.warning(this.message)
							return
						}
						if (this.type == 1 && this.fCardMediaType != 1) {
							this.$message.warning('银行卡类型为贷记卡，不可绑定')
							return
						}
						if (this.fAccountID != "") {
							this.toSave()
						} else {
							this.$message.info('请先获取验证码')
						}
					} else {

						return false;
					}
				});

			},
			toSave() {
				// 验证之后会给fAccountID赋值,值为空说明没有通过验证
				// if (this.title.indexOf('账服通') != -1) {
				// 	if (this.fAccountID == "") {
				// 		return;
				// 	}
				// }

				// let reg =/^\d{6}$/
				// if(value.split("").length != 6){
				//    this.$message({
				//        message: '验证码格式输入不正确',
				//        type: 'warning'
				//     });
				// }
				this.buttonloading = true;
				if (this.type == 1) {
					this.ApiRequestPost(this.submitUrl, {
						fAccountID: this.fAccountID,
						fCheckCode: this.ruleForm.fCheckCode,
					}).then(
						(result) => {
							console.log(result, "00000000000");
							this.buttonloading = false;
							if (result.code == "EBCustomer1017") {
								this.$router.push({
									path: "/businessme/MoneyDash"
								});
							}
							this.buttonloading = false;
						},
						(rej) => {
							this.buttonloading = false
						}
					);
				} else if (this.type == 2) {
					this.ApiRequestPostNOMess(this.submitUrl, {
						fAccountID: this.fAccountID,
						fCheckCode: this.ruleForm.fCheckCode,
						fBankTypeID: this.ruleForm.fBankTypeID,
					}).then(
						(result) => {
							console.log(result, "00000000000");
							this.buttonloading = false;
							if (result.code == "HengHeCode1000") {
								if (this.$route.query.fAccountname != undefined) {
									if (this.$route.query.fAccountname.indexOf('账服通') != -1) {
										this.$router.push({
											path: "/businessme/AccountPay"
										});
									} else {
										this.$router.push({
											path: "/businessme/MoneyDash"
										});
									}
								}
								this.$message({
									message: result.message,
									type: 'success'
								});
							} else {
								this.$message({
									message: result.message,
									type: 'error'
								});
								this.buttonloading = false;
							}
						},
						(rej) => {}
					);
				}
			}
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	/deep/ .tip {
		width: 390px;
		line-height: 16px;
	}

	.codeRlBox {
		height: 30px;
		line-height: 30px;
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 380px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.sendCode {
		margin-right: 40px;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 55px auto 16px;
		margin-left: 384px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		// width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	#setform>div {
		line-height: 20px !important;
	}

	.button-Branch {
		position: absolute;
		z-index: 2;
		top: 5px;
		left: 310px;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	.bankbox {
		cursor:no-drop;
	}
	.debitcard {
		display: inline-block;
		color: #fff;
		background-color: #0164ff;
		padding: 0px 8px;
		border-radius: 5px;
		margin-left: 10px;
		line-height: 25px;
		font-size: 14px;
	}
	.creditcard {
		display: inline-block;
		color: #fff;
		background-color: #ff575a;
		padding: 0px 8px;
		border-radius: 5px;
		margin-left: 10px;
		line-height: 25px;
		font-size: 14px;
	}
</style>
